@font-face {
    font-family: 'Respira Black';
    src: local(''), url('../assets/fonts/respira-black.woff2') format('woff2'),  url('../assets/fonts/respira-black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

@font-face {
    font-family: 'Soehne';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../assets/fonts/soehne-buch.woff2') format('woff2');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Soehne';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('../assets/fonts/soehne-kraftig.woff2') format('woff2');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Soehne Schmal';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../assets/fonts/soehne-schmal-buch.woff2') format('woff2');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Soehne Mono';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../assets/fonts/soehne-mono-kraftig.woff2') format('woff2');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Tiempos';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../assets/fonts/tiempos-text-bold.woff2') format('woff2');
    font-display: swap;
  }  
 
  @font-face {
    font-family: 'Tiempos';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../assets/fonts/tiempos-text-regular.woff2') format('woff2');
    font-display: swap;
  }
 
  @font-face {
    font-family: 'Tiempos';
    font-style: italic;
    font-weight: 400;
    src: local(''), url('../assets/fonts/tiempos-text-regular-italic.woff2') format('woff2');
    font-display: swap;
  }
  
 

  