/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul {
    /* list-style-type: circle; */
    list-style-image: url('../assets/arrow.svg');
    padding-left: 20px;

    li {
      list-style-position: outside;

      p {
        display: inline;
      }
    }
  }

  .details {
    ul {
      list-style-type: none;
    }
  }
}

@layer utilities {
  .vertical-rl {
    writing-mode: vertical-rl;
    transform: scale(-1);
  }

  .hyphenation {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -moz-hyphens: auto;
    hyphens: auto;
    -webkit-hyphens: auto;
  }
}

@layer components {
  .ShowcaseSlider .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }

  .portrait video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  .landscape video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  .style-1 {
    font-family: Tiempos;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  .style-1-mobile {
    font-family: Tiempos;
    font-size: 1rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  .style-1-small-phone {
    font-family: Tiempos;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 138%;
    text-align: center;
  }

  .style-2 {
    font-family: Tiempos;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-2-mobile {
    font-family: Tiempos;
    font-size: 1rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-3 {
    font-family: Soehne;
    font-size: 5rem;
    font-weight: 600;
    line-height: 80%;
    text-align: left;
  }

  .style-3-mobile {
    font-family: Soehne;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 80%;
    text-align: left;
  }

  .style-4 {
    font-family: 'Soehne Mono';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 138%;
    text-align: left;
  }

  .style-4-mobile {
    font-family: 'Soehne Mono';
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 138%;
    text-align: left;
  }

  .style-5,
  .style-5-mobile {
    font-family: 'Soehne Mono';
    font-size: 1rem;
    font-weight: 500;
    line-height: 138%;
    text-align: left;
  }

  .style-6 {
    font-family: 'Soehne Mono';
    font-size: 3.375rem;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
  }

  .style-6-mobile {
    font-family: 'Soehne Mono';
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
  }

  .style-7 {
    font-family: Tiempos;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-7-mobile {
    font-family: Tiempos;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-8 {
    font-family: Soehne;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 96%;
    text-align: left;
  }

  .style-8-mobile {
    font-family: Soehne;
    font-size: 2rem;
    font-weight: 600;
    line-height: 96%;
    text-align: left;
  }

  .style-9 {
    font-family: Soehne;
    font-size: 18.75rem;
    font-weight: 600;
    line-height: 80%;
    text-align: left;
  }

  .style-9-mobile {
    font-family: Soehne;
    font-size: 5.813rem;
    font-weight: 600;
    line-height: 80%;
    text-align: left;
  }

  .style-10 {
    font-family: 'Soehne Schmal';
    font-size: 11.25rem;
    font-weight: 400;
    line-height: 80%;
    text-align: left;
  }

  .style-10-mobile {
    font-family: 'Soehne Schmal';
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 80%;
    text-align: left;
  }

  .style-11 {
    font-family: Respira;
    font-size: 3rem;
    font-weight: 800;
    line-height: 125%;
    text-align: left;
  }

  .style-11-mobile {
    font-family: Respira;
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 125%;
    text-align: left;
  }

  .style-12 {
    font-family: Respira;
    font-size: 4rem;
    font-weight: 800;
    line-height: 110%;
    text-align: left;
  }

  .style-12-mobile {
    font-family: Respira;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 110%;
    text-align: left;
  }

  .style-13 {
    font-family: 'Soehne Schmal';
    font-size: 3rem;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
  }

  .style-13-mobile {
    font-family: 'Soehne Schmal';
    font-size: 2rem;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
  }

  .style-14 {
    font-family: Soehne;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  .style-14-mobile {
    font-family: Soehne;
    font-size: 1rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  /* 
        style-15 is the same as style-14
    */
  .style-16 {
    font-family: 'Soehne Schmal';
    font-size: 3rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  .style-16-mobile {
    font-family: 'Soehne Schmal';
    font-size: 1rem;
    font-weight: 400;
    line-height: 138%;
    text-align: left;
  }

  .style-17 {
    font-family: Soehne;
    font-size: 2.375rem;
    font-weight: 600;
    line-height: 96%;
    text-align: left;
  }

  .style-17-mobile {
    font-family: Soehne;
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 96%;
    text-align: left;
  }

  .style-18 {
    font-family: 'Soehne Mono';
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
  }

  .style-18-mobile {
    font-family: 'Soehne Mono';
    font-size: 2rem;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
  }

  /* additional styles, not shown in the design doc */
  .style-19 {
    font-family: 'Soehne Schmal';
    font-size: 6.875rem;
    font-weight: 400;
    line-height: 138%;
    text-align: center;
  }

  .style-19-mobile {
    font-family: 'Soehne Schmal';
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 138%;
    text-align: center;
  }

  .style-20 {
    font-family: Tiempos;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-20-mobile {
    font-family: Tiempos;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 138%;
    text-align: left;
  }

  .style-21,
  .style-21-mobile {
    font-family: Soehne;
    font-size: 2rem;
    font-weight: 600;
    line-height: 110%;
    text-align: left;
  }

  .style-22 {
    font-family: Soehne;
    font-size: 19.875rem;
    font-weight: 400;
    line-height: 80%;
    text-align: left;
  }

  .style-23 {
    font-family: Soehne;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 115%;
    text-align: left;
  }

  .style-23-mobile {
    font-family: Soehne;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 115%;
    text-align: left;
  }

  .style-23-subtitle {
    font-family: Soehne;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 115%;
    text-align: left;
  }

  .style-23-mobile-subtitle {
    font-family: Soehne;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 115%;
    text-align: left;
  }

  .style-split {
    font-family: Soehne;
    font-size: 11.25rem;
    font-weight: 400;
    line-height: 80%;
    text-align: center;
  }

  .style-split-tablet {
    font-family: Soehne;
    font-size: 5.875rem;
    font-weight: 400;
    line-height: 80%;
    text-align: center;
  }

  .style-split-mobile {
    font-family: Soehne;
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 80%;
    text-align: center;
  }

  .vinyl-contact-text-mobile {
    font-family: Soehne;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 96%;
    text-align: left;
  }

  .vinyl-contact-hl-mobile {
    font-family: 'Soehne Schmal';
    font-size: 8.5rem;
    font-weight: 400;
    line-height: 80%;
    text-align: left;
  }

  /* PDF Renderer */

  /* .single-pdf header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
  }

  .single-pdf header h1 {
    font-size: inherit;
    margin: 0;
  } */

  .single-pdf__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .single-pdf__container__document {
    margin: 1em;
  }

  .single-pdf__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .single-pdf__container__document .react-pdf__Page {
    /* max-width: 600px;
    width: 50vw; */
    height: auto;
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    /* aspect-ratio: 1.4142/1; */
  }

  .single-pdf__container__document .react-pdf__Page .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }

  /*
  .single-pdf__container__document .react-pdf__Page .react-pdf__Page__textContent,
  .single-pdf__container__document .react-pdf__Page .react-pdf__Page__annotations {
    max-width: 100%;
    height: auto;
    aspect-ratio: 1.4142/1;
  } */

  .single-pdf__container__document .react-pdf__message {
    padding: 20px;
    color: white;
  }
}