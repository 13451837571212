html {
    height: 100%;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    background-color: #f0f0ef;
}

.colorize-lilac {
    filter: brightness(0) saturate(100%) invert(66%) sepia(22%) saturate(5443%) hue-rotate(210deg) brightness(103%) contrast(103%);
}

.colorize-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

@keyframes rotateCube {
    from {
        transform: rotateX(0deg) rotateY(0deg);
    }

    to {
        transform: rotateX(360deg) rotateY(360deg);
    }
}